<template>
    <section class="salon-bookings">
        <tool-bar>
            Bookings
        </tool-bar>

        <div class="booking-table">
            <b-table detailed
                     detail-key="_id"
                     v-bind:data="bookings">
                <template>
                    <b-table-column v-slot="{ row }"
                                    field="createdAt"
                                    label="Created at">
                        {{ row.createdAt | dateFnsFormatter("d/MMM/yyyy hh:mm a") }}
                    </b-table-column>

                    <b-table-column v-slot="{ row }"
                                    field="owner"
                                    label="Customer"
                                    sortable>
                        <router-link
                            v-bind:to="{ name: '', params: { id: verifiedCustomers.find(({ _id }) => _id === row.owner)._id } }">
                            {{ verifiedCustomers.find(({ _id }) => _id === row.owner).email }}
                        </router-link>
                    </b-table-column>

                    <b-table-column v-slot="{ row }"
                                    field="items.length"
                                    label="Order Items"
                                    numeric
                                    sortable
                                    centered>
                        {{ row.items.length }}
                    </b-table-column>

                    <b-table-column v-slot="{ row }"
                                    field="items"
                                    label="Amount"
                                    numeric
                                    sortable
                                    centered>
                        HK${{ row.items.reduce((acc, item) => acc += item.skus.reduce((acc, sku) => acc += sku.hkd, 0), 0) }}
                    </b-table-column>

                    <b-table-column v-slot="{ row }"
                                    field="payment.paymentMethods"
                                    label="Payment Method"
                                    centered>
                        <span v-if="row.payment">
                            {{ row.payment.paymentMethod }}
                        </span>
                        <span v-else>
                            Pending Payment
                        </span>
                    </b-table-column>
                </template>

                <template slot="detail" slot-scope="props">
                    <b-message has-icon
                               v-if="props.row.status === 'pending'">
                        <div class="columns">
                            <div class="column is-three-quarters is-flex align-items-center">
                                <span>This order is pending payment. </span>
                            </div>
                            <div class="column is-flex align-items-center justify-content-end">
                                <button class="button"
                                        style="font-weight: 500; ">
                                    Close Order
                                </button>
                            </div>
                        </div>
                    </b-message>

                    <b-message has-icon type="is-warning"
                               v-if="props.row.status === 'accepted'">
                        <div class="columns">
                            <div class="column is-three-quarters is-flex align-items-center">
                                <span>This order has been accepted and paid for. Please arrange dispatch. </span>
                            </div>
                            <div class="column is-flex align-items-center justify-content-end">
                                <button-dispatch style="font-weight: 500; "
                                                 v-bind:order-id="props.row._id">
                                </button-dispatch>
                            </div>
                        </div>
                    </b-message>

                    <b-message has-icon type="is-success"
                               v-if="['dispatched', 'delivered'].includes(props.row.status)">
                        <div class="columns">
                            <div class="column is-three-quarters is-flex align-center">
                                <span>This order has been dispatched. </span>
                            </div>
                            <div class="column is-flex align-center">
                                <button class="button is-success"
                                        style="font-weight: 500; ">
                                    View Tracking
                                </button>
                            </div>
                        </div>
                    </b-message>

                    <div class="columns">
                        <div class="column">
                            <div class="card">
                                <header class="card-header">
                                    <p class="card-header-title">
                                        Contact and Address
                                    </p>
                                </header>
                                <div class="card-content">
                                    <div class="content">
                                        <h6 class="title is-6 is-marginless">Email</h6>
                                        <p>{{ props.row.delivery.email }}</p>
                                        <h6 class="title is-6 is-marginless">Phone</h6>
                                        <p>{{ props.row.delivery.phone }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="card">
                                <header class="card-header">
                                    <p class="card-header-title">
                                        Payment
                                    </p>
                                </header>
                                <div class="card-content">
                                    <div class="content">
                                        <h6 class="title is-6 is-marginless">Order ID</h6>
                                        <p>{{ props.row._id }}</p>
                                        <template v-if="props.row.payment">
                                            <h6 class="title is-6 is-marginless">Method</h6>
                                            <p v-if="props.row.payment.paymentMethod === 'pay_on_site'">
                                                Pay on site
                                            </p>
                                            <p v-if="props.row.payment.paymentMethod === 'cards'">
                                                Cards
                                            </p>
                                            <p v-if="props.row.payment.paymentMethod === 'alipay'">
                                                Alipay
                                            </p>
                                            <p v-if="props.row.payment.paymentMethod === 'wechat_pay'">
                                                WeChat Pay
                                            </p>
                                            <p v-if="props.row.payment.paymentMethod === 'paypal'">
                                                PayPal
                                            </p>
                                            <p v-if="props.row.payment.paymentMethod === 'payment_request_api'">
                                                {{ props.row.payment.card.methodName }}
                                            </p>
                                        </template>
                                        <template v-if="props.row.status !== 'pending'">
                                            <h6 class="title is-6 is-marginless"
                                                v-if="['cards', 'payment_request_api'].includes(props.row.payment.paymentMethod)">
                                                Details
                                            </h6>
                                            <p v-if="['cards', 'payment_request_api'].includes(props.row.payment.paymentMethod)">
                                                {{ props.row.payment.card.brand }} / {{ props.row.payment.card.last4 }}
                                            </p>
                                            <h6 class="title is-6" style="margin-bottom: 0.5rem"
                                                v-if="props.row.payment.transaction">
                                                Handler Response
                                            </h6>
                                            <pre v-if="props.row.payment.transaction">
                                                <code>{{ props.row.payment.transaction }}</code>
                                            </pre>
                                        </template>
                                        <h5 class="is-5" v-else>
                                            <span class="tag is-danger is-medium">Pending Payment</span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column is-3">
                            <div class="card">
                                <div class="card-content">
                                    <div class="content">
                                        <div class="columns is-marginless">
                                            <div class="column">
                                                <button-invoice v-bind:order-id="props.row._id"></button-invoice>
                                            </div>
                                        </div>

                                        <div class="columns is-marginless">
                                            <div class="column"
                                                 v-if="props.row.status === 'accepted'">
                                                <button-receipt v-bind:order-id="props.row._id"></button-receipt>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="content">
                                        <div class="columns is-marginless">
                                            <div class="column"
                                                 v-if="props.row.status === 'accepted'">
                                                <button-refund v-bind:order-id="props.row._id"></button-refund>
                                            </div>
                                        </div>

                                        <div class="columns is-marginless">
                                            <div class="column">
                                                <button-close v-bind:order-id="props.row._id"></button-close>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="card">
                                <header class="card-header">
                                    <p class="card-header-title">
                                        Bookings
                                    </p>
                                </header>
                                <div class="card-content">
                                    <div class="content">
                                        <article class="media"
                                                 v-for="item in props.row.items">
                                            <div class="media-left">
                                                <div class="content">

                                                </div>
                                            </div>
                                            <div class="media-content">
                                                <div class="content">
                                                    <h5 class="title is-5 is-marginless">
                                                        <span v-html="item.title['en']"></span> (<span
                                                        v-html="item.subtitle['en']"></span>)
                                                    </h5>
                                                    <p>
                                                        {{ item.skus[0].name['en'] }}
                                                    </p>

                                                    <h6 class="title is-6 is-marginless">Branch</h6>
                                                    <p>
                                                        {{ item.branch.name['en'] }}
                                                    </p>

                                                    <h6 class="title is-6 is-marginless">Date</h6>
                                                    <p>
                                                        {{ item.date | dateFnsFormatter("d/MMM/yyyy") }} {{ item.session
                                                        }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="media-right">
                                                <div class="content">
                                                    <h6 class="is-6">
                                                        <small>Total: </small>{{ item.skus[0].hkd }}
                                                    </h6>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </b-table>
        </div>
    </section>
</template>

<script>
    import ToolBar from '../../components/layouts/ToolBar'
    import ButtonInvoice from '../../components/Shop/ButtonInvoice'
    import ButtonReceipt from '../../components/Shop/ButtonReceipt'
    import ButtonRefund from '../../components/Shop/ButtonRefund'
    import ButtonClose from '../../components/Shop/ButtonClose'

    export default {
        name: 'Bookings',
        components: {
            ButtonClose,
            ButtonRefund,
            ButtonReceipt,
            ButtonInvoice,
            ToolBar
        },
        data() {
            return {}
        },
        computed: {
            verifiedCustomers() {
                return this.$store.state.users
                    .users
                    .filter(
                        ({ verified }) => verified === true
                    );
            },

            bookings() {
                return this.$store.state.salon.bookings
                    .slice()
                    .reverse();
            },

            openedRow() {
                let rows = []
                if (this.$route.params.bookingId) {
                    rows.push(this.$route.params.bookingId)
                }
                return rows
            }
        },
        methods: {}
    }
</script>

<style lang="scss" scoped>
    .salon-bookings {
        .booking-table {
            padding: 1rem;
        }
    }
</style>
